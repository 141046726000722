import React, { useState } from "react";
import Layout from "@accrosoft-ltd/vf-careers-site-theme/src/components/layout";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";

import SEO from "@accrosoft-ltd/accropress-websites-components/dist/esm/seo";
import SmartFeedFiltersComponent from "@accrosoft-ltd/vf-careers-site-theme/src/components/SmartFeedWithFilters_WT";
import WigLoader from "@accrosoft-ltd/accropress-websites-components/dist/esm/wigLoader";

export default function HomepagePage_Content(props) {
  const {
    pageContext: {
      navResultData,
      integratorData,
      contentMetaData,
      contentDataData,
      config,
    },
  } = props;

  const siteConfig = config;

  console.log(props);

  const [apiKey, setApiKey] = useState(siteConfig.apiKey || props.smartfeedID);
  const [groupOrIdParam, setGroupOrIdParam] = useState(
    siteConfig.group ? "group" : "id"
  );
  const [smartfeedCompanyData, setSmartfeedCompanyData] = useState({});
  const [appGatewayURL, setAppGatewayURL] = useState({});
  const [vacanciesRouteLoading, setVacanciesRouteLoading] = useState(true);
  const [vacanciesRouteError, setVacanciesRouteError] = useState(null);

  const [vacancyLoading, setVacancyLoading] = useState(true);

  return (
    <Layout
      siteConfig={siteConfig}
      location={props.location}
      apiKey={props.apiKey}
      groupOrIdParam={props.groupOrIdParam}
      smartfeedCompanyData={props.smartfeedCompanyData}
      appGatewayURL={props.appGatewayURL}
      vacanciesRouteLoading={props.vacanciesRouteLoading}
      vacanciesRouteError={props.vacanciesRouteError}
    >
      <SEO
        navResultData={navResultData}
        contentDataData={contentDataData}
        integratorData={integratorData}
        contentMetaData={contentMetaData}
      />
      {!props.vacanciesRouteLoading &&
        (siteConfig.siteConfig || props.smartfeedCompanyData) &&
        props.apiKey &&
        props.groupOrIdParam && (
          <>
            <div
              className="home banner page"
              style={
                {
                  //backgroundRepeat: "no-repeat",
                  //backgroundImage: `url(/banner.jpg)`,
                }
              }
            >
              <div className="container">
                <div className="col-12">
                  <div className="text-block">
                    <h1>{contentDataData[0].children[0].value}</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <WigLoader
                contentDataData={contentDataData}
                contentMetaData={contentMetaData}
                contentDataArray="0"
                contentDataChild="1"
                AccroPressProdEndpoint={config.AccroPressProdEndpoint}
                AccroPressLocalEndpoint={config.AccroPressLocalEndpoint}
                integratorID={config.integratorID}
                integratorAccessToken={config.integratorAccessToken}
                imageFormatting={
                  process.env.IMAGE_FORMATTING_SECRET ||
                  process.env.GATSBY_IMAGE_FORMATTING_SECRET
                }
              />
              <div className="blocks three-blocks">
                <div className="row">
                  {contentDataData[0].children[2].children.map((column, i) => (
                    <div className="col-12 col-md block" key={i}>
                      <div className="blockBackground">
                        <div className="quickLinkTile">
                          <Link
                            className="small"
                            to={column[1].value}
                            title={column[0].value}
                          >
                            <img src={column[2].value} />
                          </Link>
                          <div className="wrap">
                            <div className="quickLinkText">
                              <Link
                                className="btn-transparent small"
                                to={column[1].value}
                                title={column[0].value}
                              >
                                <h3>{column[0].value}</h3>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="section-title">
              <div className="d-flex justify-content-center">
                <div className="col-md-10 col-lg-10 text-center">
                  <h4 className="text-center">
                    {contentDataData[0].children[3].value}
                  </h4>
                </div>
              </div>
            </div>

            <SmartFeedFiltersComponent
              siteConfig={siteConfig}
              setVacancyLoading={setVacancyLoading}
              vacancyLoading={vacancyLoading}
              apiKey={siteConfig.apiKey || props.apiKey}
              group={
                siteConfig.group
                  ? siteConfig.group
                  : props.groupOrIdParam === "id"
                  ? false
                  : props.groupOrIdParam === "group"
                  ? true
                  : false
              }
              gdprKey={siteConfig.gdprKey || siteConfig.apiKey || props.apiKey}
              smartfeedCompanyData={
                siteConfig.smartfeedCompanyData || props.smartfeedCompanyData
              }
              CompanyGroupName={
                siteConfig.companyName || props.smartfeedCompanyData.CompanyName
              }
              OptInLink={
                siteConfig.companyCareersSite
                  ? `${siteConfig.careersSiteDomain}/job-alerts/optin/?OptInID=`
                  : siteConfig.OptInLink ||
                    `${siteConfig.careersSiteDomain}/job-alerts/${props.apiKey}/optin/?OptInID=`
              }
              UnsubscribeLink={
                siteConfig.companyCareersSite
                  ? `${siteConfig.careersSiteDomain}/job-alerts/unsubscribe/?UnsubscribeID=`
                  : siteConfig.UnsubscribeLink ||
                    `${siteConfig.careersSiteDomain}/job-alerts/${props.apiKey}/unsubscribe/?UnsubscribeID=`
              }
              VacancyPageBaseURL={
                siteConfig.companyCareersSite
                  ? `${siteConfig.careersSiteDomain}/vacancies/vacancy/`
                  : siteConfig.VacancyPageBaseURL ||
                    `${siteConfig.careersSiteDomain}/vacancies/${props.apiKey}/vacancy/`
              }
              contentDataData={props.contentDataData || {}}
              location={siteConfig.showLocationJA}
              region={siteConfig.showRegionJA}
              category={siteConfig.showCategoryJA}
              industry={siteConfig.showIndustryJA}
              jobType={siteConfig.showJobTypeJA}
              jobTime={siteConfig.showJobTimeJA}
            />
          </>
        )}
    </Layout>
  );
}
